// Generated by Framer (19eed98)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mOetOGQPp", "p1O187LFr"];

const serializationHash = "framer-YHJ3Q"

const variantClassNames = {mOetOGQPp: "framer-v-1fqehsk", p1O187LFr: "framer-v-17vl6ec"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "mOetOGQPp", "Variant 2": "p1O187LFr"}

const getProps = ({height, id, titleImage, width, ...props}) => { return {...props, pw2rO9Gu7: titleImage ?? props.pw2rO9Gu7, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mOetOGQPp"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;titleImage?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pw2rO9Gu7, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "mOetOGQPp", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-YHJ3Q", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(24px, 100vw)", ...toResponsiveImage(pw2rO9Gu7)}} className={cx("framer-1fqehsk", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mOetOGQPp"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 486, borderBottomRightRadius: 486, borderTopLeftRadius: 486, borderTopRightRadius: 486, ...style}} {...addPropertyOverrides({p1O187LFr: {"data-framer-name": "Variant 2", background: {alt: "", fit: "fill", sizes: "min(32px, 100vw)", ...toResponsiveImage(pw2rO9Gu7)}}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-YHJ3Q [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YHJ3Q .framer-14qi1fp { display: block; }", ".framer-YHJ3Q .framer-1fqehsk { height: 24px; position: relative; width: 24px; }", ".framer-YHJ3Q.framer-v-17vl6ec .framer-1fqehsk { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); width: 32px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"p1O187LFr":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"pw2rO9Gu7":"titleImage"}
 * @framerImmutableVariables false
 */
const FramerOXnu_UeHR: React.ComponentType<Props> = withCSS(Component, css, "framer-YHJ3Q") as typeof Component;
export default FramerOXnu_UeHR;

FramerOXnu_UeHR.displayName = "Project Avatar";

FramerOXnu_UeHR.defaultProps = {height: 24, width: 24};

addPropertyControls(FramerOXnu_UeHR, {variant: {options: ["mOetOGQPp", "p1O187LFr"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, pw2rO9Gu7: {title: "Title Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerOXnu_UeHR, [])